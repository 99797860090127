<template>
	<div style="height:100%;">
		<div v-if="loading" style="min-height: 300px;height:100%;" class="d-flex flex-column align-items-center justify-content-center">
			<CSpinner color="secondary"/>
			<p class="mb-0">{{messageLoader}}</p>
		</div>
		<div v-if="reloads >= 3" style="min-height: 300px;height:100%;" class="d-flex flex-column align-items-center justify-content-center">
			<p class="mb-0"><small>Esta cámara presenta problemas de conexión</small></p>
			<p class="mb-0"><small>Puedes reconectar presionando el botón</small></p>
			<CButton size="sm" class="mt-2 mx-0 d-flex align-items-center" color="secondary" variant="outline"
				@click="reload(true)">
				<font-awesome-icon icon="sync" style="color: #424242;font-size: 10px" />
				<p class="mb-0 ml-2" style="color: #424242">Reconectar</p>
			</CButton>
		</div>
		<div v-show="!loading && reloads < 3" style="min-height: 300px;height:100%;">
			<div v-if="showVideo && cameraData">
				<video :ref="setRef()" data-setup='{"fluid": true, "liveui": true}' class="video-js camera-video"></video>
				<CButtonGroup class="w-100">
					<CButton size="sm" class="mx-0 flex-fill" color="secondary" variant="outline"
						@click="ptzMove('left')">
						<font-awesome-icon icon="chevron-left" style="color: #424242;font-size: 10px" />
					</CButton>
					<CButton size="sm" class="mx-0 flex-fill" color="secondary" variant="outline"
						@click="ptzMove('up')">
						<font-awesome-icon icon="chevron-up" style="color: #424242;font-size: 10px" />
					</CButton>
					<CButton size="sm" class="mx-0 flex-fill" color="secondary" variant="outline"
						@click="ptzMove('down')">
						<font-awesome-icon icon="chevron-down" style="color: #424242;font-size: 10px" />
					</CButton>
					<CButton size="sm" class="mx-0 flex-fill" color="secondary" variant="outline"
						@click="ptzMove('right')">
						<font-awesome-icon icon="chevron-right" style="color: #424242;font-size: 10px" />
					</CButton>
					<CButton size="sm" class="mx-0 flex-fill" color="secondary" variant="outline"
						@click="reload()">
						<font-awesome-icon icon="sync" style="color: #424242;font-size: 10px" />
					</CButton>
				</CButtonGroup>
			</div>
		</div>
	</div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { getTuyaCameraVideoInfo, cameraPtzMove } from '@/api/cameras'

export default {
	name: 'CameraView',
  props: ['cameraData', 'showCameraViewComponent', 'ptzWithKeys'],
  watch: {
    showCameraViewComponent: function (data) {
			if(this.showCameraViewComponent){
				this.requestVideoUrl()
			}
			else{ this.destroyPlayer() }
    }
  },
	data () {
		return{
			showVideo: false,
			loading: true,
			messageLoader: 'Conectado con Cámara',
			cameraStream: null,
			videojsOptions: {},
			player: null,
			reloaderCount: 0,
			reloads: 0
    }
	},
	mounted(){
		if(this.showCameraViewComponent){
			this.requestVideoUrl()
		}
	},
	beforeDestroy() {
		this.destroyPlayer(true)
	},
  methods: {
		setRef(){
			this.videoRef= this.cameraData.camera_id + + new Date()
			return this.videoRef
		},
		destroyPlayer(stop = false){
			this.showVideo = false
			this.loading = true
			this.cameraStream = null
			this.videojsOptions = {}
			if (this.player) {
				this.player.dispose()
			}
			this.player = null
			this.removeListeners()
			if(stop){
				this.messageLoader = 'Conectado con Cámara'
				this.reloaderCount = 0
				this.reloads = 0
			}
		},
		ptzKeyHandler(e){
			const ptzValues = {
				37: 'left',
				38: 'up',
				39: 'right',
				40: 'down',
			}
			this.ptzMove(ptzValues[e.keyCode])
		},
		removeListeners(e){
			window.removeEventListener("keyup", this.ptzKeyHandler)
		},
		setKeyListeners(){
			window.addEventListener("keyup", this.ptzKeyHandler)
		},
		setListeners(){
			if (this.player) {
				this.player.on('error', () => {
					console.log("Errorcin")
					this.reload()
				})

				this.player.on('playing', () => {
					this.loading = false
				})
				
				this.player.tech().on('retryplaylist', () => {
					this.reloaderCount++
					if(this.reloaderCount >= 5){
						this.reloads++
						if(this.reloads < 3){
							this.reload()
						}
						else{
							if (this.player) {
								this.player.dispose()
							}
							this.player = null
						}
					}
					// console.log('retryplaylist')
				})
			}
		},
		reload(restartCounter = false){
			if(restartCounter){ this.reloads = 0 }
			this.reloaderCount = 0
			this.destroyPlayer()
			setTimeout(() => {
				this.requestVideoUrl()
			}, 500);
		},
		ptzMove(ptzDirection){
			const ptzValues = {
				'left': "6",
				'up': "0",
				'down': "4",
				'right': "2",
			}
			cameraPtzMove({camera_id: this.cameraData.camera_id, ptz: ptzValues[ptzDirection]})
		},
		async requestVideoUrl(){
			this.showVideo = true
			const data = {
				tuya_user_id: this.cameraData.tuya_user.tuya_user_id,
				camera_id: this.cameraData.camera_id
			}
			const videoInfo = await getTuyaCameraVideoInfo(data)
			if(videoInfo && videoInfo.code == 200){
				this.messageLoader = 'Cargando Video'
				this.videojsOptions = {
					sources: [{
						type: 'application/x-mpegURL',
						src: videoInfo.stream.result.url,
						withCredentials: false
					}],
					controls: true,
					height: 250,
					autoplay: true,
					muted: true,
					live: true
				}
				this.player = videojs(this.$refs[this.videoRef], this.videojsOptions, () => {
					this.setListeners()
					this.ptzWithKeys && this.setKeyListeners()
				})
			}
		}
	}
}
</script>

<style scoped>
	.camera-video{
		width: 100%;
		height: 100%;
	}
</style>