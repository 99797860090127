import {endpoints} from './endpoints'
import request from '../helpers/request'
import store from '../helpers/store.js'
import axios from "axios";

export function login(email, pass){
	return new Promise((resolve, reject) => {
		const data = {
	    email: email,
	    password: pass
	  }
    request({
      url: endpoints.login,
      method: 'post',
      data
    })
		  .then(async function (response) {
		  	setUserToken(response)
		  	const user = await getUser()
			console.log(user);
		  	if(user.user.role.user_role_id === 2 || user.user.role.user_role_id === 3 || user.user.role.user_role_id === 4){
			    resolve(response)
			  }
			  else{
			  	removeUserToken()
			  	reject({
			  		code: 401,
			  		message: 'Tu cuenta no tiene los permisos para acceder a este panel'
			  	})
			  }
		  })
		  .catch(function (error) {
			console.log(error);
		    reject(error)
		  });
	})
}

export function getDetails(userID){
	return new Promise((resolve, reject) => {
		const data = {
	    id: userID
	  }
    request({
      url: endpoints.userDetails,
      method: 'post',
      data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

function setUserToken(token){
	localStorage.setItem('token', token.access_token)
}
function removeUserToken(){
	localStorage.removeItem('token')
}

export function getUser(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.user,
      method: 'get'
    })
		  .then(function (response) {
				localStorage.setItem('userData', JSON.stringify(response.user))
        store.commit('setUserData', response.user)
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function startMonitoreo(deviceID){
	return new Promise((resolve, reject) => {
		const data = {
	    device_id: deviceID
	  }
    request({
      url: endpoints.startMonitoreo,
      method: 'post',
      data
    })
		  .then(function (response) {
		  	console.log(response)
	    	resolve(response)
		  })
		  .catch(function (error) {
	    	resolve(error)
		  });
	})
}
export function sendCustomNotification(deviceID, title, description){
	return new Promise((resolve, reject) => {
		const data = {
	    device_id: deviceID,
	    title: title,
	    message: description
	  }
    request({
      url: endpoints.sendCustomNotification,
      method: 'post',
      data
    })
		  .then(function (response) {
		  	console.log(response)
	    	resolve(response)
		  })
		  .catch(function (error) {
	    	resolve(error)
		  });
	})
}

export function getFBAuthToken(){
	return new Promise((resolve, reject) => {
		const device_id = 'adminsosclick-' + store.state.userData.id
    request({
      url: endpoints.createFirebaseAuthToken,
      method: 'post',
      data: {device_id}
    })
		  .then(function (response) {
				localStorage.setItem('fbCustomAuthtoken', response.customToken)
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function getUsersGeo(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUsersGeo,
      method: 'get',
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

/* export const getUsersGeo = async () => {
  try {
    const url = 'https://api.regionconectada.com/api/admin/user/getUsersGeo';
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    return error?.response?.data ? error.response.data : error;
  }
};  */