<template>
	<div style="background: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 100%;
    overflow: hidden;"
    :style="'height: ' + containerHeight + 'px; margin-top: ' + headerHeight + 'px;'"
    v-show="$store.state.grillaMonitoreo">
    <CRow id="remoteViewContainer">
      <CCol col="12" xl="12">
        <h4 class="text-center mb-0">Cámaras Activas</h4>
        <h6 class="text-center text-muted">Se muestran las cámaras que han sido compartidas con el Panel SOSclick.</h6>
      </CCol>
      <CCol col="3" sm="4" xl="3" v-for="(camera,index) in $store.state.tuyaCameras" :key="index">
				<CameraView :cameraData="camera" :showCameraViewComponent="$store.state.grillaMonitoreo" :key="index"/>
      </CCol>
    </CRow>
	</div>
</template>

<script>
import CameraView from './CameraView'

export default {
	name: 'TuyaCamerasGrid',
	components: {
		CameraView
  },
	data () {
		return{
      headerHeight: 56,
      containerHeight: 700,
			requestedVideoInfo: false,
			tuyaCamerasVideo: []
    }
	},
	mounted(){
    this.headerHeight = document.getElementsByTagName('header')[0].clientHeight
    this.containerHeight = document.getElementsByTagName('main')[0].clientHeight
	},
  methods: {
		startRequestVideos(){
			if(!this.requestedVideoInfo){
				this.$store.state.tuyaCameras.forEach((camera, index) => {
					this.tuyaCamerasVideo.push({loading: true})
					this.requestVideoUrls(camera, index)
				});
			}
		}
	}
}
</script>