import axios from 'axios'
//import { MessageBox } from 'element-ui'
//import store from '@/store'
//import { getToken } from '@/utils/auth'

import store from '@/helpers/store'

// create an axios instance

// const BASE = 'http://localhost:8022/api/' //Desarrollo Local
 //const BASE = 'https://devapis.anubys.cl:8022/api/' //Desarrollo AWS
 
  
    //const DEV = 'https://devapis.anubys.cl:8038/api/' //Desarrollo
    //const PROD = 'https://api.regionconectada.com/api/' //Producción

    const BASE = process.env.VUE_APP_BASE_URL

const service = axios.create({ baseURL: BASE })

service.defaults.timeout = 10000

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const contentType = config.data instanceof FormData ? 'multipart/form-data':'application/json'
    const token = localStorage.getItem('token')

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
      config.headers['company-id'] = process.env.VUE_APP_Company_ID
    }
    config.headers['Content-Type'] = contentType
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  error => {
    // do something with request error
    console.log("API: ", error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // console.log(res)
    // console.log(res.code)
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 403 || res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        localStorage.removeItem('token')
        location.reload()
      }
      return Promise.reject(res || 'Error')
    } else {
      return res
    }
  },
  async error => {
    //console.log(error)
    if(error.response && (error.response.status === 403 || error.response.status === 401)){
      await localStorage.removeItem('token')
      location.reload()
    }
    return Promise.reject(error)
  }
)

export default service