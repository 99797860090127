<template>
  <div>
    <CToaster :autohide="5000">
      <template v-for="(toast,index) in toasts">
        <CToast
          :key="index"
          :show="true"
          header="Nueva Alerta"
        >
        <div class="d-flex align-items-center">
          <p class="mb-0">El usuario {{toast.nombre}} ha generado una nueva alerta</p>
          <CButton
            color="primary"
            size="sm"
            class="m-1"
            @click="initModal(toast)"
          >
            Abrir
          </CButton>
        </div>
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="5000">
      <template v-for="(toast,index) in toastsAvisos">
        <CToast
          :key="index"
          :show="true"
          :header="toast.title"
        >
        <div class="d-flex align-items-center">
          <p class="mb-0">{{toast.message}}</p>
          <CButton
            color="primary"
            size="sm"
            class="m-1"
          >
            Abrir
          </CButton>
        </div>
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="¡Atención! Una alerta ha sido activada"
      color="primary"
      :show.sync="showModal"
      class="modal-alerta"
    >
      <template #header>
        <p class="mb-0 font-weight-bold">¡Atención! Una alerta ha sido activada</p>
        <CButton
          class="mx-2 p-0"
          @click="closeModal()"
        >
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow v-if="alertData">
        <CCol col="12" xl="12">
          <CRow>
            <CCol col="6" xl="6">
              <div class="d-flex w-100 flex-column h-100">
                <div class="d-flex justify-content-center align-items-center text-center mb-3 py-2 bg-primary text-white rounded-top">
                  <p class="mb-0">Ubicación de Alerta</p>
                </div>
                <GmapMap
                  v-if="alertData.datos.geo_usuario"
                  :center="alertData.datos.camera ? alertData.datos.camera.position : alertData.datos.geo_usuario"
                  :zoom="12"
                  class="map-container"
                  style="min-height: 300px; height: 100%">
                    <GmapMarker
                      :position="alertData.datos.geo_usuario"
                      :icon="{url: alertData.datos.camera ? require('../../assets/imgicons/ICONOS SOSRecurso 5-2@2x.png') : require('../../assets/imgicons/ICONOS SOSRecurso 2@2x.png'), scaledSize: { width: 28, height: 35 }}"
                      @click="startMonitoreo(alertData.datos)"
                    />
                    <GmapMarker
                      v-if="alertData.datos.camera"
                      :position="formatPosition(alertData.datos.camera.position)"
                      :icon="{url: require('../../assets/imgicons/ICONOS SOSRecurso 2@2x.png'), scaledSize: { width: 32, height: 40 }}"
                      :clickable="true"
                    />
                </GmapMap>
              </div>
            </CCol>
            <CCol col="6" xl="6">
              <div class="border-primary px-0 rounded">
                <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
                  <p class="mb-0 ">¡Alerta!</p>
                  <p class="mb-0">
                    <CImg
                      class="white-color-image-overlay mr-3"
                      height="18"
                      :src="require('../../assets/imgicons/ICONOS SOSRecurso 23@2x.png')"
                    />
                    {{alertData.id}}
                  </p>
                </div>
                <CRow class="p-3">
                  <CCol class="d-flex" col="12" xl="12">
                    <div class="mr-4">
                      <img
                        v-if="alertData.user.info.avatar"
                        :src="alertData.user.info.avatar"
                        class="c-mb-2 avatar-alerta-user"
                      />
                      <div class="icon-alerta-user" v-else><font-awesome-icon icon="user"/></div>
                    </div>
                    <div>
                      <p class="mb-0 text-primary font-weight-bold">{{alertData.user.name}}</p>
                      <p class="mb-0 text-primary d-flex align-items-center">
                        <CImg
                          class="mr-2"
                          height="12"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 22@2x.png')"
                        />
                        {{alertData.user.email}}
                      </p>
                      <p class="mb-0 text-primary d-flex align-items-center">
                        <CImg
                          class="mr-2"
                          height="12"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"
                        />{{alertData.user.info.telefono}}
                          <CButton
                            :disabled="$store.state.toCallData ? true:false"
                            color="primary"
                            class="ml-2 my-1"
                            @click="call(alertData.user.info.telefono, alertData.user.name)"
                            size="sm"
                          >
                            <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                            Llamar
                          </CButton>
                      </p>
                      <p class="mb-0 text-primary d-flex align-items-center">
                        <CImg
                          class="mr-2"
                          height="12"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 21@2x.png')"
                        />{{alertData.user.info.direccion}}
                      </p>
                    </div>
                  </CCol>
                </CRow>
                <CRow class="px-3" v-if="alertData.datos.type === 1">
                  <div
                    class="d-flex flex-column justify-content-center p-3 w-100">
                    <p class="mb-2 text-primary font-weight-bold">La alerta se ha generado desde un Botón SOS asociado al usuario</p>
                    <p class="mb-0 text-primary">Alias Botón SOS: {{alertData.buttonData.name}}</p>
                    <p class="mb-0 text-primary">ID Botón SOS: {{alertData.buttonData.button_id}}</p>

                    <CRow>
                      <CCol class="d-flex flex-column my-2" col="6" xl="6">
                        <p class="mb-0 text-primary">
                          Teléfono Botón: {{alertData.buttonData.phone}}
                          <CButton
                            :disabled="$store.state.toCallData ? true:false"
                            color="primary"
                            class="ml-2 my-1"
                            @click="call(alertData.buttonData.phone,alertData.user.name)"
                            size="sm"
                          >
                            <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                            Llamar
                          </CButton>
                        </p>
                        <p class="mb-0 text-primary" v-if="alertData.buttonData.info && alertData.buttonData.info.sos1">
                          Teléfono SOS 1: {{alertData.buttonData.info.sos1}}
                          <CButton
                            :disabled="$store.state.toCallData ? true:false"
                            color="primary"
                            class="ml-2 my-1"
                            @click="call(alertData.buttonData.info.sos1,alertData.user.name)"
                            size="sm"
                          >
                            <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                            Llamar
                          </CButton>
                        </p>
                      </CCol>
                      <CCol class="d-flex flex-column my-2" col="6" xl="6">
                        <p class="mb-0 text-primary" v-if="alertData.buttonData.info && alertData.buttonData.info.sos2">
                          Teléfono SOS 2: {{alertData.buttonData.info.sos2}}
                          <CButton
                            :disabled="$store.state.toCallData ? true:false"
                            color="primary"
                            class="ml-2 my-1"
                            @click="call(alertData.buttonData.info.sos2,alertData.user.name)"
                            size="sm"
                          >
                            <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                            Llamar
                          </CButton>
                        </p>
                        <p class="mb-0 text-primary" v-if="alertData.buttonData.info && alertData.buttonData.info.sos3">
                          Teléfono SOS 3: {{alertData.buttonData.info.sos3}}
                          <CButton
                            :disabled="$store.state.toCallData ? true:false"
                            color="primary"
                            class="ml-2 my-1"
                            @click="call(alertData.buttonData.info.sos3,alertData.user.name)"
                            size="sm"
                          >
                            <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                            Llamar
                          </CButton>
                        </p>
                      </CCol>
                    </CRow>
                  </div>
                </CRow>
                <CRow class="px-3" v-else>
                  <div
                    v-if="monitores.length === 0 && loadingMonitores"
                    class="d-flex justify-content-center align-items-center w-100">
                    <CSpinner
                      style="width:20px;height:20px;"
                      color="primary"
                    />
                  </div>
                  <div
                    v-for="monitor in monitores"
                    class="col-sm-12 border-bottom d-flex justify-content-between align-items-center py-3"
                    style="color: #DB131A">
                    <div class="d-flex justify-content-start align-items-center">
                      <div style="position: relative;width: 40px;text-align: center">
                        <img style="margin-right: 10px;" class="primary-color-image-overlay" height="25"
                          :src="monitor.is_monitor ? require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png') : require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
                        <div class="active-badge-indicator-monitor"></div>
                      </div>
                      <div>
                        <p class="mb-0">Nombre del Dispositivo</p>
                        <p class="mb-0">{{monitor.deviceData.device_name}}</p>
                      </div>
                    </div>
                    <div>
                      <img
                        v-if="alerta.deviceID === monitor.deviceID"
                        style="margin-right: 10px;"
                        class="primary-color-image-overlay" height="20"
                        :src="require('../../assets/imgicons/ICONOS SOSRecurso 12@2x.png')"/>
                      <CButton
                        color="primary"
                        class="ml-2 my-1"
                        @click="startMonitoreo(monitor)"
                      >
                        <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                      </CButton>
                    </div>
                  </div>
                  <div class="w-100" v-if="userAlert && userAlert.camaras">
                    <div
                      v-for="camara in userAlert.camaras"
                      class="col-sm-12 border-bottom d-flex justify-content-between align-items-center py-3"
                      style="color: #DB131A">
                      <div class="d-flex justify-content-start align-items-center">
                        <div style="position: relative;width: 40px;text-align: center">
                          <img style="margin-right: 10px;" class="primary-color-image-overlay" height="25"
                            :src="require('../../assets/imgicons/ICONOS SOSRecurso 18-2@2x.png')"/>
                          <div class="active-badge-indicator-monitor"></div>
                        </div>
                        <div>
                          <p class="mb-0">Nombre del Dispositivo</p>
                          <p class="mb-0">{{camara.name}}</p>
                          <p class="mb-0">{{camara.url}}</p>
                        </div>
                      </div>
                      <div>
                        <img
                          v-if="alertData.datos.camera && (alertData.datos.camera.id === camara.id)"
                          style="margin-right: 10px;"
                          class="primary-color-image-overlay" height="20"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 12@2x.png')"/>
                        <CButton
                          color="primary"
                          class="ml-2 my-1"
                          disabled
                          @click="startMonitoreo(monitor)"
                        >
                          <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                        </CButton>
                      </div>
                    </div>
                  </div>
                </CRow>
                <CRow>
                  <CCol col="12" xl="12">
                    <div style="border-top-color: #d0282e!important;" class="w-100 px-3 py-2 border-top d-flex justify-content-end">
                    <CButton @click="showCloseAlert(alerta)" color="success">
                      <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 19@2x.png')"/>
                      Terminar Alerta
                    </CButton>
                  </div>
                  </CCol>
                </CRow>
              </div>
              <div class="border-primary px-0 rounded mt-2">
                <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
                  <p class="mb-0 ">Prioridades asignadas al usuario</p>
                </div>
                <CRow class="px-3" v-if="alertData.user.prioridad && alertData.user.prioridad.length > 0">
                  <div class="py-2 w-100" v-for="(prioridad, index) in alertData.user.prioridad" style="color: #DB131A">
                    <CCol col="12" xl="12">
                      <div class="d-flex flex-row align-items-center">
                        <div class="mr-3">
                          <p class="mb-0 d-flex flex-row align-items-center justify-content-center text-center" style="border-radius: 50px; font-size: 18px; font-weight: bold; background-color: #DB131A; color: white;width: 30px; height: 30px;">{{index + 1}}</p>
                        </div>
                        <div claas="d-flex">
                          <p class="mb-0">
                            {{prioridad.description}}
                          </p>
                          <div class="d-flex">
                            <p class="mb-0 mr-2" style="font-size: 12px;">
                              <CIcon name="cil-calendar" class="mr-2"/>
                              {{ prioridad.start | moment("DD-MM-YYYY") }}
                            </p>
                            <p class="mb-0" style="font-size: 12px;">
                              <CIcon name="cil-calendar" class="mr-2"/>
                              {{ prioridad.end | moment("DD-MM-YYYY") }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CCol>
                  </div>
                </CRow>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol col="12" xl="12">
          <div
              style="text-align: center;min-height: 50vh;flex: 1;align-items: center;justify-content: center;display: flex;flex-direction: column;">
            <CSpinner
              style="width:4rem;height:4rem;"
              color="primary"
              grow
            />
            Cargando información de la alerta
          </div>
        </CCol>
      </CRow>
      <template #footer class="foot-alerta-modal">
        ...
      </template>
    </CModal>
  </div>

</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import {getDetails} from '../../api/user'
import {getAlert} from '../../api/alerts'
import {newToken} from '../../api/calls'
import {Device} from 'twilio-client'

export default {
  name: 'DatabaseListeners',
  props: ['showAlert', 'showCloseAlert'],
  data () {
    return {
    	showModal: false,
    	alerta: {
    		position: null
    	},
      monitores: [],
      endAlertModal: null,
      alertData: null,
      activandoMonitoreo: false,
      activandoMonitoreoMsg: 'Activando Monitor',
      toasts: [],
      toastsAvisos: [],
      admin: null,
      cuadrante: 0,
      userAlert: null,
      deviceStatus: 0,
      loadingMonitores: true
    }
  },
  watch: {
    showAlert: function(alert){
      if(alert){
        this.prepareModal(alert)
      }
      else{
        this.showModal = false
      }
    }
  },
  async mounted(){
    const self = this
    console.log(self.$store.state.alarmSound)
    const user = await localStorage.getItem('userData')
    this.admin = JSON.parse(user)
    this.cuadrante = this.admin.info.cuadrante_id
  	this.childAddedListenner()
    this.$events.listen('showAlert', eventData => this.initModal(eventData))
  },
  beforeDestroy(){
    this.$events.remove('showAlert')
  },
  methods: {
    call(phoneToCall, name){
      const self = this
      const callData = {
        phoneToCall,
        name
      }
      this.$store.commit("setCallData", callData)
    },
    closeModal(){
      this.showModal = false
      this.alertData = null
      this.activandoMonitoreo = false
      this.activandoMonitoreoMsg = 'Activando Monitor'
    },
    formatPosition(position){
      console.log(position)
      const geo = {
        lat: position.latitude,
        lng: position.longitude
      }
      return geo
    },
    childAddedListenner(){
      const self = this
      firebase.database(this.$store.state.primaryDB).ref().child('/SOSclick/Alertas/' + this.cuadrante).on("child_added", async function(snapshot, prevChildKey) {
        if(self.$store.state.alarmSound){
          const sound = new Audio(require('../../assets/sounds/Security_Breach_Alarm_Siren.wav'))
          sound.play()
        }
        var alert = snapshot.val()
        self.toasts.push(alert)
        if(alert.prioridad && alert.prioridad.length > 0){
          self.initModal(alert)
        }
      })
      firebase.database(this.$store.state.primaryDB).ref().child('/SOSclick/ButtonAlertas/' + this.cuadrante).on("child_added", async function(snapshot, prevChildKey) {
        if(self.$store.state.alarmSound){
          const sound = new Audio(require('../../assets/sounds/Security_Breach_Alarm_Siren.wav'))
          sound.play()
        }
        var alert = snapshot.val()
        console.log(alert)
        self.toasts.push(alert)
        if(alert.prioridad && alert.prioridad.length > 0){
          self.initModal(alert)
        }
      })
    },
    startMonitorFromAlert(data){
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + data.deviceID)
        .once('value', (snapshot) => {
          let datos = snapshot.val()
          datos.alerta = data
          datos.showPIP = false
          datos.startRecord = true
          this.$events.fire('startMonitoreo', datos)
        })
    },
    async initModal(alert){
      const self = this
      self.loadingMonitores = true
      self.prepareModal(alert)
      const user = await getDetails(alert.id_usuario)
      console.log(user)
      this.userAlert = user.user
      if(user.user.dispositivos.length > 0){
        self.monitores = []
        user.user.dispositivos.map(function(user){
          firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + user.device_id).once('value', function(data){
            if(data.val()){
              self.monitores.push(data.val())
            }
          })
        })
        self.loadingMonitores = false
      }
    },
    setMonitorear(data){
      this.activandoMonitoreoMsg = 'Monitor Activado. Levantando Video.'
      data.showVideo = true
      data.alerta = this.alerta
      console.log("setMonitorear: ", data)
      // this.$events.fire('startMonitoreo', data)
      // this.closeModal()
    },
    resetMonitor(monitor){
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + monitor + '/resetMonitoreo').set(true)
    },
    // startMonitoreo(){
    //   this.activandoMonitoreo = true
    //   const self = this
    //   if(this.monitores){
    //     const monitor = this.monitores.find(function(item, index){
    //       return item.deviceID === self.alerta.deviceID
    //     })
    //     if(monitor && monitor.userActivo){
    //       this.setMonitorear(monitor)
    //     }
    //     else{
    //       const monitorB = this.monitores.find(function(item, index){
    //         return item.userActivo
    //       })
    //       if(monitorB){
    //         this.setMonitorear(monitorB)
    //       }
    //     }
    //   }
    // },
    startMonitoreo(data){
      console.log("this.alertData:", this.alertData)
      this.$store.commit("setVideoCallData", {
        status: true,
        alertID: data.alerta ? data.alerta.alert_id:null,
        geoUserAlert: data.alerta ? data.alerta.geo_usuario:null,
        fullView: true,
        deviceID: data.deviceID,
        calledUserID: data.id_usuario,
        socketUrl: this.alertData.videocall.socket_url,
        socketRoom: this.alertData.videocall.socket_room,
        videocallID: this.alertData.videocall.videocall_id,
        hideVideo: false,
        silent: true
      })
    },
    prepareModal(alerta){
    	const self = this
      console.log(alerta)
      this.alerta = alerta
	    this.alerta.position = {
	    	lat: alerta.geo_usuario.latitude,
	    	lng: alerta.geo_usuario.longitude
	    }
  		this.showModal = true
	  	getAlert({id: alerta.alert_id})
	  		.then(function(resp){
          self.alertData = resp.data
          self.alertData.buttonData = alerta.buttonData
          self.alertData.videocall = alerta.videocall
          console.log(self.alertData)
	  		})
	  		.catch(function(error){
	  			console.log(error)
	  		})
    }
  }
}
</script>