<template>
	<div v-if="socket && inProgress">
		<FullView
			v-if="fullView"
			:calledUserID="calledUserID"
			:atencionID="atencionID"
			:socket="socket"
			:usersInRoom="usersInRoom"
			:localStream="localStream"
			:remoteCalledStream="remoteCalledStream"
			:remoteStreams="remoteStreams"
			:endVideoCallCallback="endVideoCall"
			:goPicInPicCallback="goPicInPic"
			:socketRoom="socketRoom"
			:rejectedCall="rejectedCall"
			:startConnection="startConnection"
		/>
		<PipView
			v-else
			:remoteCalledStream="remoteCalledStream"
			:remoteStreams="remoteStreams"
			:localStream="localStream"
			:usersInRoom="usersInRoom"
			:endVideoCallCallback="endVideoCall"
			:goFullScreenCallback="goFullScreen"
			:socketRoom="socketRoom"
		/>
	</div>
	<div v-else>
	  <CModal
	    :show="true"
	    class="videocall fullscreen-modal no-header"
	  >
  		<div class="d-flex justify-content-center align-items-center flex-column h-100">
		    <CSpinner
		      style="width:4rem;height:4rem;"
		      color="#3c4b64"
		      grow
		    />
				Por favor espera unos segundos mientras iniciamos la videollamada
			</div>
	  </CModal>
	</div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
	// Necesito pasarle socketURL,  socketRoom, calledUserID, deviceID
	import FullView from './FullView'
	import PipView from './PipView'
	import {startAtencion, endAtencion, endAtencionFixed} from '../../api/atenciones'
	import kurentoUtils from 'kurento-utils'
	import io from 'socket.io-client'
  import {createVideoCallRoom} from '../../api/atenciones'

	export default {
	  name: 'VideoCallRoom',
	  components: {FullView, PipView},
	  data(){
	    return {
			rejectedCall: '',
	    	fullView: true,
	    	inProgress: false,
	    	localWebRtcPeer: null,
	    	localStream: null,
	    	remotesWebRtcPeer: {},
	    	remoteStreams: [],
	    	remoteCalledStream: null,
	    	usersInRoom: {},
	    	roomID: null,
    		socket: null,
    		recording: false,
    		atencionID: null,
    		deviceID: null,
    		calledUserID: null,
    		socketUrl: null,
    		socketRoom: null,
    		hideVideo: false,
    		silent: false,
    		readyForConnection: false,
    		alertID: null,
				geoUserAlert: null,
				setVideoFlowingTimeout: null,
				turnServer: null
	    }
	  },
	  mounted(){
	  	const videoCallData = this.$store.state.videoCallData
	  	this.deviceID = videoCallData.deviceID
			this.socketUrl = videoCallData.socketUrl
			this.socketRoom = videoCallData.socketRoom
			this.videocallID = videoCallData.videocallID
			this.calledUserID = videoCallData.calledUserID
			this.fullView = videoCallData.fullView
			this.hideVideo = videoCallData.hideVideo
			this.silent = videoCallData.silent
      this.alertID = videoCallData.alert_id
      this.geoUserAlert = videoCallData.geo_usuario

			this.createRoomAndStart()
			this.setVideoCallListeners()
			navigator.connection.onchange = this.changeHandler
	  },
	  methods: {
	  	changeHandler(event){
	  		console.log(event)
	  	},
			setVideoCallListeners(){
				this.$events.listen('currentVideocallListeners', eventData => {
					this.handleVideoCallMessages(eventData)
				})
			},
			handleVideoCallMessages(eventData){
				switch (eventData.id) {
					case 'userNetworkSilence':
						this.sendMessage({
							id: 'admin-actionMessage',
							action: 'admin-changeUserAudio',
							to: eventData.socketID, // Array de Strings o String
							status: eventData.status
						})
						break;
					case 'userNetworkKickOut':
						this.sendMessage({
							id: 'privateRoomStatusChange',
							status: eventData.status
						})
						this.sendMessage({
							id: 'admin-actionMessageToRoom',
							action: 'admin-changePrivateRoomStatus',
							status: eventData.status
						})
						break;
				
					default:
						break;
				}
			},
	  	async createRoomAndStart(tries = 0){
	  		if(!this.socketUrl || !this.socketRoom || !this.videocallID){
		      const room = await createVideoCallRoom()
		      if(room.code === 200){
		        const videocall = room.room
						this.socketUrl = videocall.socket_url
						this.socketRoom = videocall.socket_room
						this.videocallID = videocall.id

			      this.$store.commit("setVideoCallData", {
			        status: true,
			        alertID: this.alertID,
			        geoUserAlert: this.geoUserAlert,
			        fullView: this.fullView,
			        deviceID: this.deviceID,
			        calledUserID: this.calledUserID,
			        socketUrl: this.socketUrl,
			        socketRoom: this.socketRoom,
			        videocallID: this.videocallID,
			        hideVideo: this.hideVideo,
			        silent: this.silent,
			      })
		      }
		      else if(tries <= 3){
		      	this.createRoomAndStart(tries++)
		      }
		    }
		    if(this.socketUrl && this.socketRoom && this.videocallID){
		    	this.readyForConnection = true
	    		this.startConnection()
	      }
	  	},
	  	goPicInPic() {
	  		this.fullView = false
	  	},
	  	goFullScreen(){
	  		this.fullView = true
	  	},
			setVideoFlowingCountDown(){
				this.setVideoFlowingTimeout = setTimeout(() => {
					this.reloadConnection()
				}, 7000);
			},
	  	async startConnection(){
		    this.socket = io(this.socketUrl,
			    {
			      transports: ['websocket'],
			      autoConnect: false,
			      reconnect: true,
			      forceNew: true
			    })
				this.setSocketListeners()
				this.startVideoCall()
				this.inProgress = true

			
	       const atencion = await startAtencion({videocall_id: this.videocallID})
/* 	       if(atencion.code === 200){
	         this.atencionID = atencion.atencion.id
	       } */

		firebase.database(this.$store.state.primaryDB).ref(`/videocalls/${this.videocallID}/${this.calledUserID}`).on("child_added",snapshot => {
        let data = snapshot.key
        if (data == 'rejected') { 
			this.rejectedCall = data
	  	}
      })
			},
			setSocketListeners(){
				this.socket.on('message', (data) => {
					if(data.id !== 'roomStats' && data.id !== 'videoStats'){ console.log('Message from server', data) }
				  switch (data.id) {
				    case 'startResponse':
				      this.startResponse(data.sdpAnswer);
				      break;
				    case 'remoteResponse':
				      this.remoteResponse(data.remoteSocketID, data.sdpAnswer);
				      break;
				    case 'error':
				      console.log('Error message from server: ' + data.message);
				      break;
				    case 'iceCandidate':
				      this.localWebRtcPeer.addIceCandidate(data.candidate)
				      break;
				    case 'remoteIceCandidate':
				    	this.remotesWebRtcPeer[data.remoteSocketID].addIceCandidate(data.candidate)
				      break;
				    case 'usersInRoom':
				      this.createMultipleNewEndpoints(data.usersInRoom)
				      break;
				    case 'inRoomUserChange':
				      this.inRoomUserChange(data)
				      break;
				    case 'newUserInRoom':
							console.log('newUserInRoom', data)
			  			this.addUserToRoom(data.user)
				      break;
				    case 'onVideoReady':
							console.log('onVideoReady', data)
				      this.onVideoReady(data.socketID)
				      break;
				    case 'userLeaveRoom':
				      this.destroyConnectionWithRemote(data.socketID)
				      break;
				    case 'startRecording':
				      this.recording = true
				      break;
				    case 'stopRecording':
				      this.recording = false
				      break;
				    case 'roomStats':
				      // this.handleRoomStats(data.stats)
				      break;
				    case 'videoStats':
				    	// console.log('videoStats', data.stats)
				      // handleVideoStats(data.stats)
				      break;
				    case 'mediaFlowStateChange':
				    	console.log('mediaFlowStateChange', data)
				      break;
				    default:
				      console.log('Unrecognized message: ', data);
			  	}
			  })
			},
			onVideoReady(socketID){
				if(this.usersInRoom[socketID]){
					this.createNewEndpoint(this.usersInRoom[socketID])
				}
			},
			startVideoCall(){
				const name = 'Operador Región Conectada'
				const userID = this.$store.state.userData.id
				this.join(this.socketRoom, name, userID, true)
			},
			endVideoCall(){
			  this.socket.close()
			  this.localWebRtcPeer.dispose()
			  Object.keys(this.remotesWebRtcPeer).forEach((key) => {
			    this.remotesWebRtcPeer[key].dispose()
			  })

				endAtencionFixed({videocall_id: this.videocallID})
				
				this.cleanRoom()
				this.deleteFromRTDB()
			},
			deleteFromRTDB(){
				this.videocallID
				firebase.database(this.$store.state.primaryDB).ref('/SOSclick/Denuncias/' + this.videocallID).remove()
			},
	  	hideRemoteVideo(socketID){
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-ToggleVideo',
	  			to: socketID,
	  			status: false
	  		}
			  this.sendMessage(message)
	  	},
			cleanRoom(){
	    	this.fullView = true
	    	this.inProgress = false
	    	this.localWebRtcPeer = null
	    	this.localStream = null
	    	this.remotesWebRtcPeer = {}
	    	this.remoteStreams = []
	    	this.remoteCalledStream = null
	    	this.usersInRoom = {}
	    	this.roomID = null
    		this.socket = null
    		this.recording = false
    		this.atencionID = null
    		this.deviceID = null
    		this.calledUserID = null
    		this.socketUrl = null
    		this.socketRoom = null
    		this.hideVideo = false
    		this.readyForConnection = false
    		this.turnServer = null
				this.$events.remove('currentVideocallListeners')
        this.$store.commit("setVideoCallData", {
		      status: false,
      		alertID: null,
          geoUserAlert: null,
		      fullView: true,
		      deviceID: null,
		      calledUserID: null,
		      socketUrl: null,
		      socketRoom: null,
		      videocallID: null,
		      hideVideo: false,
		      silent: false
		    })
			},
			join(roomName, userName, userID, isAdmin) {
			  this.socket.connect()
			  this.socket.emit('join', {roomName, userName, userID, isAdmin}, response => {
			  	this.addUserToRoom(response, true)
			  	this.turnServer = {
			  		turn_ip: response.turn_ip,
						turn_port: response.turn_port,
						turn_user: response.turn_user,
						turn_password: response.turn_password,
					}
			  	this.startComunnications(userName)
			  })
			},
	  	startComunnications(userName) {
			  var options = {
	    		onaddstream: (event) => { this.onAddLocalStream() },
	    		onicecandidate: (candidate) => this.onIceCandidate(candidate),
			    configuration: {
			      iceServers: [{
			        "urls":"turn:" + this.turnServer.turn_ip + ":" + this.turnServer.turn_port,
			        "username":this.turnServer.turn_user,
			        "credential":this.turnServer.turn_password,
			      }]
			    },
			    mediaConstraints: {
			      audio: {
					deviceId: this.$store.state.audioInputDevice
				  },
			      video: {
		        	width: { min: 640, ideal: 1280, max: 1920 },
		        	height: { min: 480, ideal: 720, max: 1080 },	
			      }
			    }
			  }
			  const self = this
			  this.localWebRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function(error) {
			    if(error) return console.log(error)
					self.localStream = this.getLocalStream()
			    this.generateOffer((error, offerSdp) => self.onOffer(error, offerSdp))
			  });
			},
			onAddLocalStream(){
				this.localStream = this.localWebRtcPeer.getLocalStream()
			},
			onOffer(error, offerSdp) {
			  if(error) return console.log("error: ", error);
			  const message = {
			    id : 'start',
			    sdpOffer : offerSdp
			  }
			  this.sendMessage(message)
			},
			sendMessage(message){
				this.socket.emit('message', message)
			},
			onIceCandidate(candidate) {
			  var message = {
			    id : 'onIceCandidate',
			    candidate : candidate
			  }
			  this.sendMessage(message)
			},
			startResponse(sdpAnswer) {
			  this.localWebRtcPeer.processAnswer(sdpAnswer)
			},
			remoteResponse(remoteSocketID, sdpAnswer) {
			  this.remotesWebRtcPeer[remoteSocketID].processAnswer(sdpAnswer)
			},
			addUserToRoom(user, local = false){
				console.log("USER: ", user)
				console.log("calledUserID:", this.calledUserID)
			  const usersInRoom = Object.assign({}, this.usersInRoom)
			  user.local = local
			  usersInRoom[user.socketID] = user
			  this.usersInRoom = usersInRoom
			},
			inRoomUserChange(user){
				const userInfo = user.user
			  let usersInRoom = Object.assign({}, this.usersInRoom)
			  if(usersInRoom[user.socketID]){
			  	usersInRoom[user.socketID] = Object.assign(usersInRoom[user.socketID], userInfo)
			  	this.usersInRoom = usersInRoom
			  }
				if(this.calledUserID === user.userID){
					this.remoteCalledStream = {
						...userInfo,
						...this.remoteCalledStream
					}
				}
			},
			createMultipleNewEndpoints(usersInRoom){
			  usersInRoom.map((user) => {
			  	this.addUserToRoom(user)
			    this.createNewEndpoint(user)
			  })
			},
			createNewEndpoint(user){
			  const {socketID} = user
			  var options = {
    			// remoteVideo: video,
			    configuration: {
			      iceServers: [{
			        "urls":"turn:" + this.turnServer.turn_ip + ":" + this.turnServer.turn_port,
			        "username":this.turnServer.turn_user,
			        "credential":this.turnServer.turn_password,
			      }]
			    },
			    onicecandidate: (candidate) => { this.onIceCandidateRemote(candidate, socketID) },
	    		onaddstream: (event) => { this.onAddRemoteStream(user) }
			  }
			  const self = this
			  this.remotesWebRtcPeer[socketID] = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function(error) {
			    if(error) return false
			    this.generateOffer( (error, offer) => { self.onRemoteOffer(error, offer, socketID) } )
			  });
			},
			onAddRemoteStream(user){
				const remoteStream = this.remotesWebRtcPeer[user.socketID].getRemoteStream()
				// this.remoteCalledStream = {
				// 	...user,
				// 	stream: remoteStream
				// }
				console.log('onAddRemoteStream: ', remoteStream)
				if(this.calledUserID === user.userID){
					this.remoteCalledStream = {
						...user,
						stream: remoteStream
					}
					// if(this.hideVideo){this.hideRemoteVideo(user.socketID)}
				}
				else{
					let remoteStreams = [...this.remoteStreams]
					remoteStreams.push({
						...user,
						stream: remoteStream
					})
					this.remoteStreams = remoteStreams
				}
			},
			onIceCandidateRemote(candidate, socketID) {
			  const message = {
			    id : 'onIceCandidateRemote',
			    socketID,
			    candidate : candidate
			  };
			  this.sendMessage(message)
			},
			onRemoteOffer(error, offerSdp, socketID) {
			  if(error) return false
			  const message = {
			    id : 'newRemoteOffer',
			    socketID,
			    sdpOffer : offerSdp
			  }
			  this.sendMessage(message)
			},
			destroyConnectionWithRemote(socketID){
				if(this.remotesWebRtcPeer[socketID]){
			  	this.remotesWebRtcPeer[socketID].dispose()
			  	delete this.remotesWebRtcPeer[socketID]
			  }
			  const remoteStreamIndex = this.remoteStreams.findIndex((item) => {
			  	return item.socketID = socketID
			  })
			  if(remoteStreamIndex !== -1){
					let remoteStreams = [...this.remoteStreams]
			  	remoteStreams.splice(remoteStreamIndex, 1)
					this.remoteStreams = remoteStreams
			  }
			  const userInfo = this.usersInRoom[socketID]
		  	delete this.usersInRoom[socketID]
				if(this.calledUserID === userInfo.userID){
					this.remoteCalledStream = null
				}
			},
			switchAudioInput() {
				const pc = this.localWebRtcPeer.peerConnection;

				navigator.mediaDevices.getUserMedia({
			      audio: {
					deviceId: this.$store.state.audioInputDevice
				  },
			      video: {
		        	width: { min: 640, ideal: 1280, max: 1920 },
		        	height: { min: 480, ideal: 720, max: 1080 },	
			      }
    			})
    			.then((stream) => {
    			  const [MediaStreamTrack] = stream.getAudioTracks();
    			    const sender = pc.getSenders().find((s) => s.track.kind === MediaStreamTrack.kind);
					  sender.replaceTrack(MediaStreamTrack);
    			  })
    			.catch((err) => {
    			  console.error(`Error happened: ${err}`);
    			});
			}
	  	},
	  	watch: {
    	 '$store.state.audioInputDevice': function (val) {
      		this.switchAudioInput()
    	},
	}
	}
</script>