import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
/* import { CTable } from '@coreui/vue' */
import { iconsSet as icons } from './assets/icons/icons.js'
import './permissions'
import "babel-core/register"
import "babel-polyfill"
import * as VueGoogleMaps from 'vue2-google-maps'
import * as firebase from 'firebase/app'
import 'firebase/database'
import VueEvents from 'vue-events'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCamera, faUser, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faSync, faVolumeUp, faLink, faExclamation,
  faTrashAlt, faUserEdit, faMapMarkedAlt, faPhone, faPlus, faMinus, faListOl, faLightbulb, faPalette, faInfoCircle,
  faAlignLeft, faMobileAlt, faShare, faExpand, faFemale, faBuilding, faHome, faSearchLocation, faBlind, faNotesMedical,
  faRandom, faVolumeMute, faEdit, faVideo, faVideoSlash, faExclamationTriangle, faDesktop, faSlash, faHistory, faStop, faCircle,
  faCheck, faFire, faFireAlt, faMicrophone, faMicrophoneSlash, faCog, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import GmapCluster from "vue2-google-maps/dist/components/cluster"
import store from './helpers/store.js'
import JsonExcel from "vue-json-excel";

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: { "image": true },
}); 


Vue.component('datetime', Datetime);

Vue.component('GmapCluster', GmapCluster);
Vue.component("downloadExcel", JsonExcel);

Vue.config.performance = true
Vue.use(CoreuiVue)
/* Vue.use(CTable) */
Vue.use(VueEvents)
Vue.use(require('vue-moment'))
library.add(
  faCamera, faUser, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faSync, faVolumeUp, faLink, faExclamation,
  faTrashAlt, faUserEdit, faMapMarkedAlt, faPhone, faPlus, faMinus, faListOl, faLightbulb, faPalette, faInfoCircle,
  faAlignLeft, faMobileAlt, faShare, faExpand, faFemale, faBuilding, faHome, faSearchLocation, faBlind, faNotesMedical,
  faRandom, faVolumeMute, faEdit, faVideo, faVideoSlash, faExclamationTriangle, faDesktop, faSlash, faHistory, faStop, faCircle,
  faCheck, faFire, faFireAlt, faMicrophone, faMicrophoneSlash, faCog, faTrash)
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* const firebaseConfig = {
  apiKey: "AIzaSyAG7ToUumc5o0RLc_QEh2FPZLv1Iu_HpUc",
  authDomain: "sosclick-1d067.firebaseapp.com",
  databaseURL: "https://sosclick-1d067.firebaseio.com",
  projectId: "sosclick-1d067",
  storageBucket: "sosclick-1d067.appspot.com",
  messagingSenderId: "614190041654",
  appId: "1:614190041654:web:472e522e02a23dc8986697"
}; */


//Desarrollo
/* const firebaseConfig = {
  apiKey: "AIzaSyC8T6qNHJULXuDnrD3gYzS1Ys-3ZCFZ2NM",
  authDomain: "sosdenuncias-7ef33.firebaseapp.com",
  projectId: "sosdenuncias-7ef33",
  databaseURL: "https://regionconectada-dev.firebaseio.com",
  storageBucket: "sosdenuncias-7ef33.appspot.com",
  messagingSenderId: "99419521214",
  appId: "1:99419521214:web:755fa9ea09078077c3deef",
  measurementId: "G-23BWT27ZVV"
} */

const firebaseConfig = {
  apiKey: "AIzaSyC8T6qNHJULXuDnrD3gYzS1Ys-3ZCFZ2NM",
  authDomain: "sosdenuncias-7ef33.firebaseapp.com",
  projectId: "sosdenuncias-7ef33",
  databaseURL: process.env.VUE_APP_databaseURL,
  storageBucket: "sosdenuncias-7ef33.appspot.com",
  messagingSenderId: "99419521214",
  appId: "1:99419521214:web:755fa9ea09078077c3deef",
  measurementId: "G-23BWT27ZVV"
}


firebase.initializeApp(firebaseConfig);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAVx5gJCh9MATmWOFIXOONY-YCc9R3yPlg',
		libraries: 'visualization',
  },
  installComponents: true
})

import { CChartLine } from '@coreui/vue-chartjs'
Vue.component('CChartLine', CChartLine)

new Vue({
  el: '#app',
  router,
  icons,
  store,
  template: '<App/>',
  components: {
    App
  },
})
