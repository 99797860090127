<template>
	<div class="videocalllist-container" :class="expanded ? 'expanded':null">
		<div class="remotes-container" v-show="expanded">
			<div v-for="(remote, index) in remotes" class="videocall-self-container" :key="index">
				<video v-if="remote.stream" autoplay :srcObject.prop="remote.stream" class="video-elements w-100"></video>
				<div class="user-name-video" >{{remote.userName}}</div>
		    <CButton class="button-list" color="dark" size="sm" @click="muteUser(remote)">
					<font-awesome-icon icon="volume-mute"/>
		    </CButton>
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between">
	    <CButton @click="expanded = !expanded" color="light" size="sm">
				<font-awesome-icon :icon="expanded ? 'chevron-up':'chevron-down'"/>
	    </CButton>
	    <small>Otros usuarios en sala: {{remotes.length}}</small>
		<div style="display:flex; padding: 8px;">
	    <CButton color="dark" size="sm" :disabled="remotes.length <= 0" @click="muteUsers()">
				<font-awesome-icon icon="volume-mute"/>
	    </CButton>
		<CButton :color="mute ? 'dark':'red'" size="sm" style="margin-left:12px;" @click="toggleMute()">
			<font-awesome-icon icon="microphone-slash" style=" cursor:pointer;"/>
	    </CButton>
		<CButton color="dark" size="sm" style="margin-left:12px;" @click="toggleModalAudio()">
			<font-awesome-icon icon="cog" style=" cursor:pointer;"/>
	    </CButton>
		</div>
	  </div>
		<CButton :color="privateRoom ? 'danger':'primary'" size="sm" block
			@click="kickOutUsers()"
			v-c-tooltip="
				privateRoom ?
				'Permitir Ingreso Red: Si hay usuarios de la red de seguridad que estén en espera de conexión serán conectados a la videollamada' : 
				'Prohibir Ingreso Red: Puedes tener una conversación privada con el usuario afectado, esto sacará de la sala de videoconferencia a los otros usuarios que estén conectados'
			">
			<font-awesome-icon :icon="privateRoom ? 'video':'video-slash'" class="mr-2"/> {{privateRoom ? 'Cambiar a Conversación Normal':'Cambiar a Conversación Privada'}}
		</CButton>
	</div>
</template>

<script>
	export default {
	  name: 'VideoCallList',
	  props: ['remotes', 'localStream'],
	  data(){
	    return {
	    	expanded: false,
				mutedRoom: false,
				privateRoom: false,
				mute: true
	    }
	  },
		watch: {
			remotes: function() {
				console.log('remotes: ', this.remotes)
			}
		},
	  mounted(){
	  	console.log(this.remotes)
	  },
	  methods: {
			muteUser(user){
				const data = {
					id: 'userNetworkSilence',
					socketID: user.socketID,
					status: this.mutedRoom
				}
				this.$events.fire('currentVideocallListeners', data)
			},
			muteUsers(){
				this.mutedRoom = !this.mutedRoom
				const socketIDs = this.remotes.map((remote) => {
					return remote.socketID
				})
				const data = {
					id: 'userNetworkSilence',
					socketID: socketIDs,
					status: this.mutedRoom
				}
				this.$events.fire('currentVideocallListeners', data)
			},
			kickOutUsers(){
				this.privateRoom = !this.privateRoom
				const socketIDs = this.remotes.map((remote) => {
					return remote.socketID
				})
				const data = {
					id: 'userNetworkKickOut',
					socketID: socketIDs,
					status: this.privateRoom
				}
				this.$events.fire('currentVideocallListeners', data)
			},
			toggleModalAudio() {
      			this.$store.commit('setModalAudioSettings', !this.$store.state.modalAudioSettings)
    		},
		toggleMute() {
			const stream = this.localStream;
			this.mute = !this.mute
			stream.getAudioTracks()[0].enabled = this.mute
    	}
	  }
	}
</script>