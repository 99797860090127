const endpoints = {
	getComplaintsHeatMap: '/admin/complaints/getComplaintsHeatMap',
	getComplaintsByCompany: '/admin/complaints/getComplaints',
	getComplaintByType: '/admin/complaints/getComplaintByType',
	getComplaintTypes: '/admin/complaints/getComplaintTypes',
	typeInfo: '/admin/companies/getComplaintTypeCreateInfo',
	createComplaintType: '/admin/companies/addCompanyComplaintType',
	updateComplaintType: '/admin/companies/updateCompanyComplaintType',
	deleteComplaintType: '/admin/companies/deleteCompanyComplaintType',
	getComplaintsById: '/complaints/getByID',
	changeComplaintStatus : '/admin/complaints/addComplaintStatus',
	markComplaintAsPublic : '/public/markComplaintAsPublic',
	completeComplaintAdding: '/complaints/completeComplaintAdding',
	getCompanyComplaintCategories: '/admin/complaints/getCompanyComplaintCategories',
	addComplaintCategory: '/admin/complaints/addComplaintCategory',
	removeComplaintCategories: '/admin/complaints/removeComplaintCategories',
	updateComplaintCategory: '/admin/complaints/updateComplaintCategory',
}

export {endpoints};