<template>
  <vue-draggable-resizable
  	:resizable="false"
  	:x="0"
  	:y="150"
  	class-name="pip-monitoreo"
  	:z="9999999999" 
  	:parent="true"
    v-if="this.$store.state.trackingInBigMap || (trackingInfo && routes)">
    <div>
      <div class="d-flex flex-row align-items-stretch py-3 px-4">
        <CButtonClose style="font-size: 28px;position: absolute;top: 10px;right: 10px;font-weight: 400;z-index: 9999;" @click="finish()"/>
        <CImg
          class="mb-1"
          style="object-fit: cover;width: 50px;height:50px;border-radius: 10px; border: 2px solid #CBCBCB;"
          v-if="this.$store.state.contentInfoUser"
          :src="this.$store.state.contentInfoUser.info.avatar"
        />
        <div
          style="
            object-fit: cover;
            width: 50px;
            height:50px;
            border-radius: 10px;
            border: 2px solid #CBCBCB;
            font-size: 25px;
            color: #d0282e;
            background: #f2f2f2;"
          class="d-flex justify-content-center align-items-center"
          v-else><font-awesome-icon icon="user"/></div>
        <div class="ml-3 d-flex flex-column">
          <h6 style="font-size: 14px;" class="text-primary">{{trackingInfo.nombre}}</h6>
          <h6
            style="font-size: 12px;font-weight: 400;color: #666666;text-transform: capitalize;"
            v-show="trackingInfo.deviceData">
            Dispositivo {{trackingInfo.deviceData.device_brand}} {{trackingInfo.deviceData.device_name}}
          </h6>
        </div>
      </div>
      <CContainer class="bottom-container small-buttons-custom" fluid>
        <CRow class="mt-2" :gutters="false">
          <CCol col="4" xl="4" class="px-1">
            <CButton
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0 px-1"
              variant="outline"
              style="flex: 1;"
              @click="setInBigMap()"
            >
              <CIcon name="cilLocationPin" class="icons-svg"/> Ver en mapa general
            </CButton>
          </CCol>
          <CCol col="4" xl="4" class="px-1">
            <CButton
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0 px-1"
              :class="showSearchHistorial ? 'text-white':''"
              :variant="showSearchHistorial ? '':'outline'"
              style="flex: 1;"
              @click="() => { this.showSearchHistorial = !this.showSearchHistorial }"
            >
              <CIcon name="cilHistory" class="icons-svg"/> Historial de Ubicación
            </CButton>
          </CCol>
          <CCol col="4" xl="4" class="px-1">
            <CButton
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0 px-1"
              :class="showNotas ? 'text-white':''"
              :variant="showNotas ? '':'outline'"
              style="flex: 1;"
              @click="() => { this.showNotas = !this.showNotas }"
            >
              <CIcon name="cilPencil" size="xl" class="icons-svg"/> Notas
            </CButton>
          </CCol>
          <CCol v-show="showSearchHistorial" col="12" xl="12" class="my-1 px-1">
            <div class="d-flex flex-row align-items-stretch">
              <CInput
                class="mb-0 mr-2 flex-grow-1"
                type="date"
                placeholder="Fecha Búsqueda"
                v-model='fechaBusqueda'
              />
              <CButton
                :disabled="searching"
                class="text-light d-flex justify-content-around align-items-center px-1 py-2"
                style="background-color: #666666;font-size: 12px;width:100px;"
                @click="searchHistorial()"
              >
                <CSpinner color="white" size="sm" v-if="searching"/>
                <p class="mb-0" v-else>Buscar</p>
              </CButton>
            </div>
            <CAlert v-for="(toast,index) in toasts" :color="toast.color" closeButton :show.sync="currentAlertCounter">
              {{toast.message}}
            </CAlert>
          </CCol>
        </CRow>
      </CContainer>
      <CContainer v-show="showNotas" class="bottom-container small-buttons-custom" fluid>
        <NewNoteForm :type="1" :userID="trackingInfo.id_usuario" :deviceID="trackingInfo.deviceID"/>
      </CContainer>
      <div v-if="!this.$store.state.trackingInBigMap">
  	    <GmapMap
          v-show="!showNotas" ref="trackingMap"
          :zoom="mapZoom"
          :center="getCenter()"
  	      :options="{ streetViewControl: false, mapTypeControl: false, zoomControl: true, }"
  	      class="map-container" style="height: 300px; width: 400px;" >
          <div
              v-for="(route, index) in routes"
              :key="'routes' + index">
            <GmapMarker
              :key="'trakingB' + trackingInfo.deviceID + index"
              :ref="'trakingB' + trackingInfo.deviceID + index"
              :visible="route && route.length > 0 && !hideTramo.includes(index) ? true:false"
              :position="route[route.length-1]"
              :clickable="true"
              customInfo="Work"
              :icon="{url: require('../../../assets/imgs/pin1.png'), scaledSize: { width: 32, height: 44 }}"
              :zIndex="3"
            />
            <GmapMarker
              :key="'trakingA' + trackingInfo.deviceID"
              :ref="'trakingA' + trackingInfo.deviceID"
              :visible="route && route.length > 0 && !hideTramo.includes(index) ? true:false"
              :position="route[0]"
              :clickable="true"
              customInfo="Work"
              :icon="{url: require('../../../assets/imgs/pin1.png'), scaledSize: { width: 32, height: 44 }}"
              :zIndex="2"
            />
            <InfoPointTracking
							v-for="(point, indexB) in route" :point="point"
							v-if="route && route.length > 0 && indexB%5 === 0 && !hideTramo.includes(index)"
							:key="'point'+indexB"/>
            <GmapPolyline
              v-if="!hideTramo.includes(index)"
              :key="'route' + index"
              :options="{
                strokeWeight: 5,
                strokeColor: '#d0282e',
                strokeOpacity: 0.4
              }"
              :path="route"  
            />
          </div>
          <div v-if="showActualRoute && actualRoute && actualRoute.length > 0">
            <GmapMarker
              :key="'trakingActualB' + trackingInfo.deviceID"
              :ref="'trakingActualB' + trackingInfo.deviceID"
              :position="actualRoute[actualRoute.length-1]"
              :clickable="true"
              customInfo="Work"
              :icon="{url: require('../../../assets/imgs/pin4.png'), scaledSize: { width: 32, height: 44 }}"
              :zIndex="3"
            />
            <GmapMarker
              :key="'trakingActualA' + trackingInfo.deviceID"
              :ref="'trakingActualA' + trackingInfo.deviceID"
              :position="actualRoute[0]"
              :clickable="true"
              customInfo="Work"
              :icon="{url: require('../../../assets/imgs/pin5.png'), scaledSize: { width: 32, height: 44 }}"
              :zIndex="2"
            />
            <InfoPointTracking v-for="(point, indexB) in actualRoute" :point="point" v-if="actualRoute && actualRoute.length > 0 && indexB%5 === 0"/>
            <GmapPolyline
              key="route"
              :options="{
                strokeWeight: 5,
                strokeColor: 'green',
                strokeOpacity: 0.4
              }"
              :path="actualRoute"  
            />
          </div>
  		  </GmapMap>
        <div v-show="!showNotas" class="mb-0 py-2 px-3">
          <p class="py-1 my-1" style="font-size: 10px; border-bottom: 1px solid #f2f2f2; border-top: 1px solid #f2f2f2;">Esta ubicación corresponde a la que entrega el dispositivo cuando se activa la opción de seguimiento de posición en la aplicación SOSclick.</p>
          <div class="d-flex flex-row p-0 m-0 w-100 justify-content-center align-items-center">
            <div class="d-flex flex-row p-0 m-0 w-100 justify-content-center align-items-center">
              <CImg
                class="mb-0"
                style="object-fit: contain;width: 14px;height:20px;"
                :src="require('../../../assets/imgs/pin2.png')"
              />
              <p class="mb-0 ml-1" style="font-size: 10px;">Punto Inicio Ruta</p>
            </div>
            <div class="d-flex flex-row p-0 m-0 w-100 justify-content-center align-items-center">
              <CImg
                class="mb-0"
                style="object-fit: contain;width: 14px;height:20px;"
                :src="require('../../../assets/imgs/pin1.png')"
              />
              <p class="mb-0 ml-1" style="font-size: 10px;">Punto Final Ruta</p>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!showNotas" class="mb-0 py-2 px-3">
        <p v-show="routes.length > 0 || actualRoute.length > 0" class="pb-0 mb-0" style="font-size: 10px;">Puedes marcar o desmarcar los tramos que deseas visualizar en el mapa.</p>
        <CButton
          v-show="actualRoute && actualRoute.length > 0"
          color="primary"
          class="py-0 px-1"
          style="height: 25px;margin: 1px;font-size: 10px;"
          :variant="showActualRoute ? '':'outline'"
          @click="toggleActualRoutes()"
        >
          Actual
        </CButton> 
        <CButton
          v-show="routes.length > 5"
          color="primary"
          class="py-0 px-1"
          style="height: 25px;margin: 1px;font-size: 10px;"
          :variant="routes.length === hideTramo.length ? 'outline':''"
          @click="toggleRoutes()"
        >
          Todo
        </CButton>         
        <CButton
          v-show="routes.length > 5"
          color="primary"
          class="py-0 px-1"
          style="height: 25px;margin: 1px;font-size: 10px;"
          :variant="routes.length !== hideTramo.length ? 'outline':''"
          @click="toggleRoutes(false)"
        >
          Ninguno
        </CButton>
        <CButton
          v-for="(route, index) in routes"
          :key="'routesIndex' + index"
          color="primary"
          class="py-0 px-1"
          style="width: 25px;height: 25px;margin: 1px;"
          :variant="hideTramo.includes(index) ? 'outline':''"
          @click="hideShowTramo(index)"
        >
          {{index + 1}}
        </CButton>
      </div>
      <div v-if="this.$store.state.trackingInBigMap && this.$store.state.trackingInBigMap.status" class="d-flex align-items-stretch py-3 px-4 small-buttons-custom">
        <CRow class="p-0 m-0 w-100 justify-content-center align-items-center">
          <CCol class="d-flex py-0 px-1" col="4" xl="4">
            <p class="text-center mb-0" style="font-size: 12px;">Siguiendo en Mapa</p>
          </CCol>
          <CCol class="d-flex py-0 px-1" col="4" xl="4">
            <CButton
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0 px-1"
              style="flex: 1;"              
              variant="outline"
              @click="centerMap()"
            >
              <CIcon name="location" size="xl" class="icons-svg"/> Centrar
            </CButton>
          </CCol>
          <CCol class="d-flex py-0 px-1" col="4" xl="4">
            <CButton
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0 px-1"
              style="flex: 1;"
              variant="outline"
              @click="finish()"
            >
              <CIcon name="cilCheckAlt" size="xl" class="icons-svg primary"/> Terminar
            </CButton>
          </CCol>
        </CRow>
      </div>
	  </div>
  </vue-draggable-resizable>
</template>

<script>
import { mapGetters } from "vuex"
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import * as firebase from 'firebase/app'
import 'firebase/database'
import {searchTrackingHistorial} from '../../../api/tracking'
import NewNoteForm from '../Components/NewNoteForm'
import InfoPointTracking from '../InfoWindow/InfoPointTracking'
import moment from 'moment'

export default {
  name: 'SoloTracking',
  components: {VueDraggableResizable, NewNoteForm, InfoPointTracking},
  computed: {
    ...mapGetters(["soloTrackingUser"])
  },
  data(){
    return {
      cuadrante: this.$store.state.userData.info.cuadrante_id,
    	trackingInfo: null,
      trackingInfoPersistent: null,
      deviceStatus: 9,
      routes: [],
      actualRoute: [],
      routesFromDB: [],
      fechaBusqueda: null,
      toasts: [],
      hideTramo: [],
      currentAlertCounter: 4,
      showSearchHistorial: false,
      mapCenter: { lat: -33.4373063, lng: -70.634297 },
      showNotas: false,
      searching: false,
      showActualRoute: true,
      mapZoom: 12
    }
  },
  mounted(){
    this.fechaBusqueda = moment().format("YYYY-MM-DD")
  },
  watch:{
    soloTrackingUser: function(data){
      if(data){
        this.trackingInfoPersistent = data
      	this.createListenners(data)
      }
      else{
      	this.removeListenners(this.trackingInfoPersistent)
      }
      this.trackingInfo = data
      if(this.$store.state.trackingInBigMap){
        this.trackingInfo = this.$store.state.trackingInBigMap.trackingInfo
      }
    }
  },
  methods: {
    resetState(){
      const data = {
        cuadrante: this.$store.state.userData.info.cuadrante_id,
        trackingInfo: null,
        deviceStatus: 9,
        routes: [],
        actualRoute: [],
        routesFromDB: [],
        fechaBusqueda: moment().format("YYYY-MM-DD"),
        toasts: [],
        hideTramo: [],
        currentAlertCounter: 4,
        showSearchHistorial: false,
        mapCenter: { lat: -33.4373063, lng: -70.634297 },
        showNotas: false,
        searching: false,
        showActualRoute: true,
        mapZoom: 12
      }
      Object.assign(this.$data, data)
    },
    getCenter(){
      if(this.actualRoute.length > 0 && this.showActualRoute){
        return this.actualRoute[this.actualRoute.length-1]
      }
      else if(this.routes.length > 0){
        return this.routes[0][this.routes[0].length-1]
      }
      this.mapZoom = 12
      return this.mapCenter
    },
    toggleActualRoutes(){
      this.showActualRoute = !this.showActualRoute
      if(this.$store.state.trackingInBigMap && this.$store.state.trackingInBigMap.status){
        this.setInBigMap()
      }
    },
    toggleRoutes(all = true){
      if(all){
        this.hideTramo = []
      }
      else{
        let all = []
        for (var i = 0; i < this.routes.length; i++) {
          all.push(i)
        }
        this.hideTramo = all
      }
      if(this.$store.state.trackingInBigMap && this.$store.state.trackingInBigMap.status){
        this.setInBigMap()
      }
    },
    hideShowTramo(index){
      const found = this.hideTramo.findIndex(function(item){
        return item === index
      })
      if( found === -1 ){
        this.hideTramo.push(index)
      }
      else{
        this.hideTramo.splice(found, 1)
      }
      if(this.$store.state.trackingInBigMap && this.$store.state.trackingInBigMap.status){
        this.setInBigMap()
      }
    },
    formatRoutesFromDB(){
      if(this.routesFromDB){
        let routes = []
        this.routesFromDB.map(function(item){
          routes.push(item.route)
        })
        this.routes = routes
        if(this.routes.length > 0){
          this.toasts.push({
            title: 'Múltiples tramos encontrados',
            message: 'Encontramos ' + this.routes.length + ' tramos realizados en esa fecha. Puedes marcar o desmarcar los que deseas visualizar abajo.',
            color: 'warning'
          })
        }
        if(this.$store.state.trackingInBigMap && this.$store.state.trackingInBigMap.status){
          this.setInBigMap()
        }
      }
    },
    searchHistorial(){
      const self = this
      this.currentAlertCounter = 4
      this.toasts = []
      this.searching = true
      searchTrackingHistorial({date: this.fechaBusqueda, device_id: this.trackingInfo.deviceID })
        .then(function(resp){
          self.searching = false
          if(resp.tracking.length > 0){
            self.routesFromDB = resp.tracking
            self.formatRoutesFromDB()
          }
          else{
            self.routesFromDB = resp.tracking
            self.formatRoutesFromDB()
            self.toasts.push({
              title: 'Error',
              message: 'No encontramos tramos en esa fecha.',
              color: 'danger'
            })
          }
        })
        .catch(function(error){
          console.log(error)
          self.searching = false
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al realizar la búsqueda, por favor intenta nuevamente modificando los parámetros.',
            color: 'danger'
          })
        })
    },
    setInBigMap(){
      const routes = this.routes.filter((item, index) => {
        return !this.hideTramo.includes(index)
      })
      this.$store.commit("setTrackingInBigMap", {
        status: true,
        trackingInfo: this.trackingInfo,
        actualRoute: this.actualRoute,
        showActualRoute: this.showActualRoute,
        hideTramo: this.hideTramo,
        routes
      })
      this.closeSoloTracking()
    },
  	closeSoloTracking(){
    	this.$store.commit("setSoloTrackingUser", null)
  	},
    finish(){
      this.$store.commit("setSoloTrackingUser", null)
      this.$store.commit("setTrackingInBigMap", null)
      this.resetState()
    },
    centerMap(){
      this.$events.fire('centerMapSoloTracking')
    },
    createListenners(trackingInfo){
      const self = this
      const path = 'SOSclick/tracking/' + this.cuadrante + '/' + trackingInfo.id_usuario + '/' + trackingInfo.deviceID
      firebase.database(this.$store.state.trackingDB).ref(path).on("child_added", function(snapshot) {
        let datos = snapshot.val()
        if(snapshot.key === 'route'){ self.actualRoute = datos }
      })
      firebase.database(this.$store.state.trackingDB).ref(path).on("child_changed", function(snapshot) {
        let datos = snapshot.val()
        if(snapshot.key === 'route'){ self.actualRoute = datos }
      })
    },
    removeListenners(trackingInfo){
      const path = 'SOSclick/tracking/' + this.cuadrante + '/' + trackingInfo.id_usuario + '/' + trackingInfo.deviceID
      firebase.database(this.$store.state.trackingDB).ref(path).off("child_added")
      firebase.database(this.$store.state.trackingDB).ref(path).off("child_changed")
    	this.trackingInfo = null
    }
  }
}
</script>
<style scoped>
  .alert{padding: 5px;font-size: 11px;margin-bottom: 0;}
  .alert >>> button.close{padding: 2px 5px;}
</style>