import { endpoints } from './endpoints'
import request from '@/helpers/request'

export function getComplaintsHeatMap() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getComplaintsHeatMap,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function getComplaintsHeatMapByType(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getComplaintsHeatMap,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function getComplaintsByCompany(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getComplaintsByCompany,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function getComplaintByType(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getComplaintByType,
			method: 'get',
			params: data,
			timeout: 60000				
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function getComplaintTypes(id) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.getComplaintTypes}`,
			method: 'get',
			params: {parent_id: id}
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function createComplaintType(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createComplaintType,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function updateComplaintType(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateComplaintType,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function deleteComplaintType(id) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.deleteComplaintType}?company_complaint_type_id=${id}`,
			method: 'delete'
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function getTypeInfo() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.typeInfo,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}



export function getComplaintById(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getComplaintsById,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function changeComplaintStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.changeComplaintStatus,
			method: 'post',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function markComplaintAsPublic(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.markComplaintAsPublic,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}
export function completeComplaintAdding(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.completeComplaintAdding,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function getCompanyComplaintCategories(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyComplaintCategories,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}

export function addComplaintCategory(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addComplaintCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}

export function removeComplaintCategories(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeComplaintCategories,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}

export function updateComplaintCategory(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateComplaintCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}