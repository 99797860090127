import {endpoints} from './endpoints'
import request from '../helpers/request'

export function startAtencion(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.startAtencion,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function endAtencionFixed(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.endAtencionFixed,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function createVideoCallRoom(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createVideoCallRoom,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function initVideoCallWithDevice(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.initVideoCallWithDevice,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}