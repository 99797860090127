<template>
	<div class="sosclick-app-container m-0 p-0" v-if="ready || inLogin">
  	<router-view></router-view>
  	<!-- <DatabaseListeners v-if="!inLogin" :showAlert="showAlert" :showCloseAlert="(alert) => prepareShowEndAlert(alert)" /> -->
  	<!-- <ModalEndAlert v-if="!inLogin" :showModal="showEndAlert" :alertData="alertData" :closeModal="(show) => closeEndAlert(show)" /> -->
    <!-- <Monitoreo v-if="showMonitoreo" :showCloseAlert="(alert) => prepareShowEndAlert(alert)"/> -->
    <!-- <TuyaCamerasGrid v-if="showMonitoreo" /> -->
    <!-- <LateralBarUsers v-if="!inLogin && showMonitoreo" /> -->
    <VideoCallRoom  v-if="!inLogin && $store.state.videoCallData.status" />
		<ModalComplaint v-if="$store.state.showingComplaint" />
    <!-- <ModalSearchUsers v-if="$store.state.showUserModalSearch" :showModal="$store.state.showUserModalSearch" :closeModal="() => closeSearchUserModal()"/> -->
    <Calls v-if="!inLogin" />
    <!-- <SoloTracking v-if="!inLogin" />
    <ReportECG v-if="!inLogin" />
    <UploadFile v-if="!inLogin" /> -->
  </div>
  <div class="sosclick-app-container m-0 p-0 d-flex flex-column align-items-center justify-content-center" style="height: 100vh" v-else>
    <img src="./assets/sosdenuncia/sosdenuncia.png" width="200">
    <div class="d-flex align-items-center justify-content-center">
      <CSpinner
        color="primary"
        style="width:20px;height:20px;"
      />
      <h5 class="m-0 ml-2">Cargando datos de cuadrante</h5>
    </div>
  </div>
</template>

<script>
import DatabaseListeners from './views/notifications/DatabaseListeners'
import ModalEndAlert from './views/notifications/ModalEndAlert'
import Monitoreo from './views/widgets/Monitoreo'
import TuyaCamerasGrid from './views/widgets/TuyaCamerasView/TuyaCamerasGrid'
import Calls from './views/widgets/Calls'
import SoloTracking from './views/widgets/Tracking/SoloTracking'
import ModalSearchUsers from './views/widgets/modalSearchUsers'
import ReportECG from './views/widgets/ReportECG'
import UploadFile from './views/widgets/Components/UploadFile'
import LateralBarUsers from './views/widgets/LateralBarUsers'
import VideoCallRoom from './views/videocall/VideoCallRoom'
import ModalComplaint from './views/widgets/Complaints/ModalComplaint'

import {getUser, getFBAuthToken} from './api/user.js'
import { getCompanyConfig } from "./api/companyConfig.js";

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export default {
  name: 'App',
  components: {DatabaseListeners, ModalEndAlert, Monitoreo, Calls, ModalSearchUsers, ReportECG, SoloTracking, UploadFile, LateralBarUsers, VideoCallRoom, TuyaCamerasGrid, ModalComplaint},
  data () {
    return {
      showAlert: null,
      showEndAlert: false,
      alertData: null,
      showMonitoreo: false,
      showModalSearchUsers: false,
      ready: false,
      inLogin: true
    }
  },
  mounted(){
    this.checkMonitoreoHeight()
    this.getCompanyConfiguration()
		// this.$store.commit("setVideoCallData", {
		// 	status: true,
		// 	alertID: 1,
		// 	geoUserAlert: null,
		// 	fullView: true,
		// 	calledUserID: 5,
		// 	socketUrl: 'https://vcsocket.sosdenuncias.com:8443/',
		// 	socketRoom: 'testing',
		// 	videocallID: 2,
		// 	hideVideo: false,
		// 	silent: false
		// })
  },
  watch:{
    $route (to, from){
      if(to.name !== "Login"){
        this.inLogin = false
        if(!this.$store.state.userData || from.name == "Login"){ this.commitUser() }
      }
      else{
        this.inLogin = true
      }
    }
  },
  methods: {
    getCompanyConfiguration() {
    getCompanyConfig().then((res) => {
        this.$store.commit('setConfigClick2call', res.company.click2call_config)
        this.$store.commit('setConfigTwitter', res.company.twitter_config)
        this.$store.commit('setConfigCompany', res.company.config)
        this.$store.commit('setComplaintTypesHeader', res.company.complaint_types)
    })
  },
    async commitUser(){
      const self = this
      await getUser()
      this.ready = await this.initializeFirebaseDBS()
    },
    initializeFirebaseDBS(){
      console.log(process.env.VUE_APP_databaseURL);
      return new Promise(async (resolve, reject) => {
        const firebaseConfig = {
					apiKey: "AIzaSyC8T6qNHJULXuDnrD3gYzS1Ys-3ZCFZ2NM",
					authDomain: "sosdenuncias-7ef33.firebaseapp.com",
					projectId: "sosdenuncias-7ef33",
					databaseURL: process.env.VUE_APP_databaseURL,
					storageBucket: "sosdenuncias-7ef33.appspot.com",
					messagingSenderId: "99419521214",
					appId: "1:99419521214:web:755fa9ea09078077c3deef",
					measurementId: "G-23BWT27ZVV"
				}
        const primaryDB = await firebase.initializeApp(firebaseConfig, 'primaryDB')
        this.$store.commit('setPrimaryDB', primaryDB)

        resolve(true)
      })
    },
    checkMonitoreoHeight(){
      const self = this
      const height = document.getElementsByTagName('main')[0]
      if(height){
        this.showMonitoreo = true
      }
      else{
        setTimeout(function() {
          self.checkMonitoreoHeight()
        }, 500);
      }
    },
    prepareShowEndAlert(alert){
      this.alertData = alert
      this.showAlert = false
      this.showEndAlert = true
    },
    closeEndAlert(show = true){
      this.showEndAlert = false
      this.showAlert = show ? this.alertData : null
      this.alertData = null
    },
    closeSearchUserModal(){
      this.$store.commit('setShowUserModalSearch', false)
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
